import { Message, MessageBox } from "element-ui";
import Vue from "vue";
export function messageBox(
  api,
  data = {},
  title = "此操作将永久删除该条信息, 是否继续"
) {
  return new Promise(async (resolve, rej) => {
    let res = await MessageBox.confirm(title, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).catch((err) => err);
    if (res) {
      if (res === "confirm") {
        let { name, token } = JSON.parse(localStorage.userInfo);
        let submitData = {
          name,
          token,
          ...data,
        };
        Vue.prototype.$axios.post(api, submitData).then((res) => {
          let resData = res.data;

          if (resData.code == "0") {
            Message({
              type: "success",
              message: resData.message,
            });
            resolve(true);
          } else {
            Message.error(resData.message);
          }
        });
      } else {
        resolve(false);
        // Message.info("已取消删除");
      }
    }
  });
}
