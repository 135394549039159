export const Api = {
  // 用户
  user: {
    get: "user",
    add: "userAdd",
    edit: "userEdit",
    dele: "userDel",
  },

  // 门店
  shop: {
    get: "shopinfo", //获取门店列表
    add: "shopinfoAdd", //添加门店
    edit: "shopinfoEdit", //修改门店
    dele: "shopinfoDel", //删除门店
  },
  // 设备
  equipment: {
    get: "shopdevice", //获取门店列表
    add: "shopdeviceAdd", //添加门店
    edit: "shopdeviceEdit", //修改门店
    dele: "shopdeviceDel", //删除门店
  },
  // 门店游戏
  shopGame: {
    get: "shopgameprice",
    add: "shopgamepriceAdd",
    edit: "shopgamepriceEdit",
    dele: "shopgamepriceDel",
    getPartners: "partners", //获取设备/场地/内容方
  },
  // 所有游戏
  game: {
    get: "gamelist",
    add: "",
    edit: "",
    dele: "",
  },
  // 订单
  orderList: {
    get: "orderLista",
  },
  // 合作伙伴
  partner: {
    get: "partners",
    add: "addpartner",
    edit: "mpartner",
    dele: "dpartner",
  },
  // 退款
  refund: {
    get: "sOrderList",
    agree:"wx_refund",
    disagree:"sOrderCancel"
  },
  // 轮播
  banner: {
    get: "banner",
    add: "bannerAdd",
    edit: "bannerEdit",
    dele: "bannerDel",
  },
  // 所有游戏
  allGame: {
    get: "gamelist",
    add: "gamelistAdd",
    edit: "gamelistEdit",
    dele: "gamelistDel",
  },
  // 财务
  financial: {
    get: "caiwuList",
    userShopList:"userShopList"
  },
};
